import React from 'react';
import { Link } from 'react-router-dom';

// const UrlPath = window.location.pathname;

const BigButtons = (props) =>{

        return(
            <div className="SeterContainer">
            <div className="SeterBlock">
            
                <div  className="buter bl_1">
                {/* <div className="ClickerIs"></div> */}
                <span className="iconer ike pb1"></span>
                    {/* <span className="t1" href="/">Հրապարակումներ</span> */}
                    <Link to="/programs/companies" className="p1">կազմակերպություններ</Link>
                </div>

                <div  className="buter bl_2">
                    <span className="iconer ike pb2 IsWrite2"></span>
                    <Link className="p2" to="/programs/government">կառավարություն</Link>
                </div>
            </div>
        </div>  
        );
    }

export default BigButtons;