import React from 'react';

import Slider from "react-slick";

import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";

import axios from 'axios';

import { token, host, weekDays } from '../assets/options';

import RBCarousel from "react-bootstrap-carousel";

class SimpleSlider extends React.Component {
    _isMounted = false;
    constructor(){
        super();

        this.state = {
            data: {
                yerevan: {},
                kotayk: {},
                ararat: {},
                armavir: {},
                gegharkunik: {},
                aragatsotn: {}
            }
        }
    }

    componentDidMount () {
        this._isMounted = true;

        axios.post(host + '/api/weather/', {}, {headers: {'authorization': token}})
        .then(res => {
            if (res.status === 200) {
                if(this._isMounted) {
                    this.setState({data: res.data.data});
                }
            } else {
              const error = new Error(res.data.error);
              throw error;
            }
          })
        .catch(err => {
            console.error(err.response?err.response.data.error:err);
        }); 
    }
    
    componentWillUnmount () {
        this._isMounted = false;
    }

    render () {
        const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1
        };

        var today = new Date();

        return (
        <div>
            <Slider {...settings}>

            <div>
                <div className="ClimateBlocer">
                    <div className="ClimatImage">
                        {this.state.data.yerevan.state?<img alt="err" className="ClimatImgMobile" src={`${host}/public/img/weatherIcons/${this.state.data.yerevan.state.toLowerCase()}.png`}></img>:null} 
                    </div>
                    <div className="blck_1">
                        <p>Երեւան</p>
                        <p>{today.getHours()}:{today.getMinutes()}, {weekDays[today.getDay() - 1]}</p>
                    </div>
                    <div className="blck_2">
                        <p className="sombl">{this.state.data.yerevan.temperature>0?'+':''}{this.state.data.yerevan.temperature}</p>
                    </div>
                </div>
            </div>

            <div>
                <div className="ClimateBlocer">
                    <div className="ClimatImage">
                        {this.state.data.kotayk.state?<img alt="err" className="ClimatImgMobile" src={`${host}/public/img/weatherIcons/${this.state.data.kotayk.state.toLowerCase()}.png`}></img>:null} 
                    </div>
                    <div className="blck_1">
                        <p>Կոտայք</p>
                        <p>{today.getHours()}:{today.getMinutes()}, {weekDays[today.getDay() - 1]}</p>
                    </div>
                    <div className="blck_2">
                        <p className="sombl">{this.state.data.kotayk.temperature>0?'+':''}{this.state.data.kotayk.temperature}</p>
                    </div>
                </div>
            </div>

            <div>
                <div className="ClimateBlocer">
                    <div className="ClimatImage">
                        {this.state.data.ararat.state?<img alt="err" className="ClimatImgMobile" src={`${host}/public/img/weatherIcons/${this.state.data.ararat.state.toLowerCase()}.png`}></img>:null} 
                    </div>
                    <div className="blck_1">
                        <p>Արարատ</p>
                        <p>{today.getHours()}:{today.getMinutes()}, {weekDays[today.getDay() - 1]}</p>
                    </div>
                    <div className="blck_2">
                        <p className="sombl">{this.state.data.ararat.temperature>0?'+':''}{this.state.data.ararat.temperature}</p>
                    </div>
                </div>
            </div>

            <div>
                <div className="ClimateBlocer">
                    <div className="ClimatImage">
                        {this.state.data.armavir.state?<img alt="err" className="ClimatImgMobile" src={`${host}/public/img/weatherIcons/${this.state.data.armavir.state.toLowerCase()}.png`}></img>:null} 
                    </div>
                    <div className="blck_1">
                        <p>Արմավիր</p>
                        <p>{today.getHours()}:{today.getMinutes()}, {weekDays[today.getDay() - 1]}</p>
                    </div>
                    <div className="blck_2">
                        <p className="sombl">{this.state.data.armavir.temperature>0?'+':''}{this.state.data.armavir.temperature}</p>
                    </div>
                </div>
            </div>

            <div>
                <div className="ClimateBlocer">
                    <div className="ClimatImage">
                        {this.state.data.gegharkunik.state?<img alt="err" className="ClimatImgMobile" src={`${host}/public/img/weatherIcons/${this.state.data.gegharkunik.state.toLowerCase()}.png`}></img>:null} 
                    </div>
                    <div className="blck_1">
                        <p>Գեղարքունիք</p>
                        <p>{today.getHours()}:{today.getMinutes()}, {weekDays[today.getDay() - 1]}</p>
                    </div>
                    <div className="blck_2">
                        <p className="sombl">{this.state.data.gegharkunik.temperature>0?'+':''}{this.state.data.gegharkunik.temperature}</p>
                    </div>
                </div>
            </div>

            <div>
                <div className="ClimateBlocer">
                    <div className="ClimatImage">
                        {this.state.data.aragatsotn.state?<img alt="err" className="ClimatImgMobile" src={`${host}/public/img/weatherIcons/${this.state.data.aragatsotn.state.toLowerCase()}.png`}></img>:null} 
                    </div>
                    <div className="blck_1">
                        <p>Արագածոտն</p>
                        <p>{today.getHours()}:{today.getMinutes()}, {weekDays[today.getDay() - 1]}</p>
                    </div>
                    <div className="blck_2">
                        <p className="sombl">{this.state.data.aragatsotn.temperature>0?'+':''}{this.state.data.aragatsotn.temperature}</p>
                    </div>
                </div>
            </div>

            </Slider>
        </div>
        );
  }
}

class MobileWeather extends React.Component {
    _isMounted = false;
    
    constructor () {
        super();

        this.state = {
            ad: {},
            slider_time: 0
        }
    }

    async componentDidMount () {
        this._isMounted = true;

        var res = await axios.post(host + '/api/ads/bySize/360x200', {}, {headers: {'authorization': token}})

        this.setState({
            ad: res.data.data,
            slider_time: res.data.time
        })
    }

    componentWillUnmount () {
        this._isMounted = false;
    }
    
    render () {
        return (
            <div className="MobileClimatContainer">

            {this.state.ad.length ? <RBCarousel
                    autoplay={true}
                    pauseOnVisibility={true}
                    slideshowSpeed={this.state.slider_time * 1000}
                    version={4}
                    indicators={false}
                >
                    {this.state.ad.map((item, key) => {
                        return (
                            <a href={item.url} key={key} target="_blank" rel="noopener noreferrer">
                                <div className="climat_Image_Container" 
                                style={{backgroundImage: `url(${`${host}/public/uploads/${item.img}`})`}}></div> 
                            </a>
                        )
                    })}
                </RBCarousel> : null}
                
            <div className="mbct">
            <div className="TitleMobile">
                <p>Եղանակը մարզերում</p>
            </div>
                <SimpleSlider/>
                {/* <div className="TitleMobile">
                    <p>Մեկ շաբաթվա եղանակը</p>
                </div>
                <div className="ClimateBlocer">
                    <div className="ClimatImage">
                        <img alt="err" className="ClimatImgMobile" src={require('../views/images/Icons/Weather/175855-weather-icon-compilation/png/cloud.png')}></img> 
                    </div>
                    <div className="blck_1">
                        <p>Արմավիր</p>
                        <p>15:30, ուրբաթ</p>
                    </div>
                    <div className="blck_2">
                        <p className="sombl">+14</p>
                    </div>
                </div> */}
            </div>
        </div>
        );
    }
}

export default MobileWeather;