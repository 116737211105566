import React from 'react';

import axios from 'axios';

import { host, token } from '../../assets/options';

import IMask from 'imask';

class Profile extends React.Component {
    _isMounted = false;
    
    constructor(props) {
        super(props);

        this.state = {
            item: {}
        }
    }

    async componentDidMount () {
        this._isMounted = true;

        let item = (await axios.post(host + '/api/services/' + this.props.data, {}, {headers: {'authorization': token}})).data.data;

        var masked = IMask.createMask({
            mask: '+{374} 00 00 00 00',
        });

        item.phone_number = masked.resolve(item.phone_number.toString());

        this.setState({
            item: item
        })
    }
    
    componentWillUnmount () {
        this._isMounted = false;
    }

    checkEmailOrWebsite (str) {
        const isEmail = /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;

        if (isEmail.test(str)) {
            return <a href={`mailto: ${str}`} target="_blank">{str}</a>
        } else {
            if (!str.includes("http")) {
                return <a href={"http://"+str} target="_blank">{str}</a>
            }

            return <a href={str} target="_blank">{str}</a>
        }
    }
    
    render () {
        var { item } = this.state;
        
        return (
            <div className="ProfileContainer grtc" onClick={this.props.CloseProfileHandler}>
                <div className="ProfileBlock">
                    <span  className="close mycloser"  onClick={this.props.CloseProfileHandler}><i className="fas fa-chevron-left"></i></span>
                    <div className="TopTitleBlock">
                    <div className='fxs'>
                    <div className="ImageBlock">
                            {item.img?<img alt="profile" src={`${host}/public/uploads/${item.img}`}></img>:null}
                        </div>
                        <div className="Mytexts Mytexts--services">
                            <p>{item.name}</p>
                            <p>{item.category_name}</p>
                        </div>
                    </div>
                    </div>
                    <div className="ProfileDis">
                        
                        {item.education_title ? <div className="TextDivs">
                            <p className="txtmy">{item.education_title}</p>
                            <p className="txtmy">
                                <span>{item.education}</span>
                            </p>
                        </div> : null}
                        
                        {item.profession_title ? <div className="TextDivs">
                            <p className="txtmy">{item.profession_title}</p>
                            <p className="txtmy">
                                <span>{item.profession}</span>
                            </p>
                        </div> : null}

                        {item.experience_title ? <div className="TextDivs">
                            <p className="txtmy">{item.experience_title}</p>
                            <p className="txtmy">{item.experience}</p>
                        </div> : null}

                        <div className="InBottom">
                            <div className="TextDivs ">
                                <p className="txtmy">Հեռախոսահամար</p>
                                <p className="txtmy">{item.phone_number}</p>
                            </div>
                            
                            <div className="TextDivs">
                                <p className="txtmy">Կայքէջ/Էլ․ հասցե</p>
                                <p className="txtmy txtmy__email">
                                    {item.email ? this.checkEmailOrWebsite(item.email) : null}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Profile;