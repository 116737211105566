import React from 'react';
import LeftMenu from './LeftMenu';

import { host } from '../../assets/options';

const Item = props => {
    let item = props.data;

    return (
        <a href={item.url} target="_blank" className='publications--href' rel="noopener noreferrer">
            <div className="flex-item flexItemMenu flexItemMenu__publication">
                <div className="Shadow"></div>

                <div className="mycontext publication__mycontext">
                    <p className='publication__title'>{item.name}</p>
                </div>
                
                <div className="img_container" style={{backgroundImage: `url(${item.img !== '' ? " "+host+"/public/uploads/"+item.img+" " : ''})`}}></div>
            </div>
        </a>
    );
}

class Items extends React.Component {
    constructor (props) {
        super(props);

        this.state = {
            items: [],
            showAll: false,
            companyName: ''
        }
    }

    componentWillReceiveProps(nextProps) {
        this.setState({
            items: nextProps.data,
            companyName: nextProps.company
        });
    }

    loadItems () {
        var items = [];

        let mql = window.matchMedia('(max-width: 1024px)').matches;

        var length = (!this.state.showAll && mql && this.state.items.length > 3)?3:this.state.items.length;
        
        for (var i = 0; i < length; i++) {
            items.push(<Item key={i} data={this.state.items[i]} />);
        } 

        if (!this.state.showAll && mql && this.state.items.length > 3) items.push(<button className="seeMoreBtn" onClick={()=>{this.setState({showAll: true})}}>Տեսնել բոլորը</button>);

        return items;
    }

    render () {
        return (
            <React.Fragment>
                <div className="MyBodyContainer InMenu">  
                    <LeftMenu this={this.props.this} />

                    <div className="flex-container flexfixer fcr">
                        {this.loadItems()}
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default Items;