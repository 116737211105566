import React from 'react';
import LeftMenu from './LeftMenu';
import { Link } from 'react-router-dom';

import { months } from '../../assets/options';

import ReactHtmlParser from 'react-html-parser';

const Item = props => {
    var item = props.data;

    item.date = new Date(item.date);
        return (
            <div className="trainingItem">
                <div className="MyIcon"></div>
                
                <div className="DataCont">
                    <div className="contexter">
                        <p className="rl">
                        {months[item.date.getMonth()]} {item.date.getDate()}, {item.date.getFullYear()}
                        </p>
                        <div className="rb" >
                            <a href={item.url} target="_blank" rel="noopener noreferrer"  onMouseOver={props.handleOver} onMouseLeave={props.handleOut}>
                                {item.name}
                            </a>

                            <div className="desc">
                                {ReactHtmlParser(item.desc)}
                                {/* {ReactHtmlParser(item.desc.replaceAll("<br>", ""))} */}
                            </div>
                            {/* <div className="desc" dangerouslySetInnerHTML={{__html: item.desc}}></div> */}

                            <div className="MyButtonShow trgshow--desktop">
                                <a href={item.url} target="_blank" rel="noopener noreferrer" 
                                className="showLink showLink--desktop">իմանալ ավելին <div className="swimg"></div></a>
                            </div> 
                        </div>
                        
                        <div className="MyButtonShow trgshow">
                            <a href={item.url} target="_blank" rel="noopener noreferrer" className="showLink ">իմանալ ավելին <div className="swimg"></div></a>
                        </div>      
                    </div>
                </div>
            </div>
        ); 

}

class Items extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            items: [],
            showAll: false,
            companyName: ""
        }
    }

    componentDidMount () {
        this.props.this.getData();
    }
    
    componentWillReceiveProps (nextProps) {
        this.setState({
            items: nextProps.data,
            companyName: nextProps.company
        });
    }

    loadItems () {
        var items = [];

        let mql = window.matchMedia('(max-width: 1024px)').matches;

        var length = (!this.state.showAll && mql && this.state.items.length > 3)?3:this.state.items.length;
        
        for (var i = 0; i < length; i++){
            items.push(<Item key={i} data={this.state.items[i]} handleOver={this.props.handleOver} handleOut={this.props.handleOut}/>);
        } 

        if (!this.state.showAll && mql && this.state.items.length > 3) items.push(<button className="seeMoreBtn" onClick={()=>{this.setState({showAll: true})}}>Տեսնել բոլորը</button>);

        return items;
    }

    render () {
        return (
            <React.Fragment>
                <div className="MyBodyContainer InMenu">  
                    {/* <LeftMenu this={this.props.this} />  */}

                    <div className="ResultsContainer">
                        {/* <p className="categoryTitle">այգու հիմնում</p> */}
                        <p className="SeachResults tragr training">ԱՄՍԱԹԻՎ</p>
                        <p className="SeachResults tragr training">ԹԵՄԱ</p>
                    </div> 

                    <div className="flex-container flexfixer aygflex training">
                        {this.loadItems()}
                    </div>
                </div>
            </React.Fragment>
        );
    }
}
export default Items;