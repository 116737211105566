import React from 'react';
import { Link } from 'react-router-dom';
import Header from '../../components/Header';
import Weather from '../../components/Weather';
import Footer from '../../components/Footer';
import Items from './Items';
import MobileWeather from '../../components/MobileWeather';
import RoutesNav from '../../components/RoutesNav';
import MobileCategories from './MobileCategories';
// import BigButtons from './BigButtons';

import axios from 'axios';

import {token, host} from '../../assets/options';

class Garden extends React.Component{
    _isMounted = false;
    constructor(props){
        super(props);
        this.state={
            page:'',
            menu:true,
            buttons:true,
            ShowBurgerMenu:false,
            IsActiveButton:' ',
            data: [],
            img: '',
            categoryName: ''
        }
        // this.MouseOverHandler = this.MouseOverHandler.bind(this);
    }
    componentDidMount(){
        this._isMounted = true;

        document.body.removeAttribute('id');
        window.scrollTo(0,0);
        document.querySelector('.aygiActive').style.color = "#5BCA90";
    }
    componentWillUnmount(){
        this._isMounted = false;
    }
    async getData(category, name){
        var find = {}
        if(category !== '') find.category = category;
        let data = (await axios.post(host + '/api/garden/', find, {headers: {'authorization': token}})).data;
        
        if(this._isMounted)
            this.setState({
                data: data.data,
                img: data.main.img,
                categoryName: name,
                isVideo: data.main.img.split('.')[data.main.img.split('.').length - 1] === 'mp4'?true:false
            });
    }
    handleOver = (event) => {
        event.target.parentNode.style.backgroundColor = '#30483C';
        event.target.style.color = 'white';
        event.target.parentNode.children[0].style.display = 'none';
        console.log('this is:', event.target);
      }
      CloseBurgerMenuHandler = (event) =>{
        this.setState({ShowBurgerMenu:false});
        document.body.removeAttribute('id');
    }  
    OpenBurgerMenuHandler = (event) => {
        this.setState({ShowBurgerMenu:true});
        document.body.id = 'overBody';
    }    
    render(){
    return (
    <div className="My-Container str garden">
                  <div className="BurgerMenu" onClick={this.OpenBurgerMenuHandler}>
                    <div></div>
                    <div></div>
                    <div></div>
               </div> 
            {
                this.state.ShowBurgerMenu ? <RoutesNav CloseBurgerMenuHandler={this.CloseBurgerMenuHandler} class={'Routing_nav posfixer MobileFixers'}/>:null
            }
    <Link to="/" className="My_Logo"></Link>
            <Header/>
            <div className="TitleBlock" style={{backgroundImage: `url(${this.state.img !== '' && !this.state.isVideo?`${host}/public/uploads/${this.state.img}`:''})`}}>
            <div className="mytitle_2">
                    <div className="mytxt">
                        <p>Գլխավոր</p>
                    </div>
                </div>
                <div className="ctext">
                    <h1>Այգի</h1>
                </div>

                {this.state.isVideo?<video className="bgVideo" ref={el=>this.video = el} style={{width: '100%'}} playsInline autoPlay loop muted>
                    <source src={`${host}/public/uploads/${this.state.img}`} type="video/mp4" />
                </video>:null}
            </div>
            {/*  */}
            <MobileCategories this={this}/>
             {/* <BigButtons  handleOver={this.handleOver}/> */}
             <Items this={this} data={this.state.data} category={this.state.categoryName} />
            <Weather />
            <MobileWeather/>
            <Footer/>
    </div>);
    }
}

export default Garden;