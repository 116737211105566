import React from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect
} from "react-router-dom";

import './assets/css/App.css';
import './assets/css/fonts.css';
import './assets/css/media.css';

import Home from './views/Home/Home';
import Publications from './views/Publications/Publications';
import Consultation from './views/Consultation/Consultation';
import Garden from './views/Garden/Garden';
import Services from './views/Service/Services';
import Programs from './views/Programs/Programs';
import Govement from './views/Govement/Govement';
import Trainings from './views/Trainings/Trainings';

import withTracker from './withTracker';

const Noter = () =>{
  return<h1>Net</h1>
}

function App() {
  return (
    <Router>
        <div className="App">
          <Switch>
            <Route exact path="/" component={withTracker(Home)}/>
            <Route exact path="/trainings" component={withTracker(Trainings)}/>
            <Route exact path="/programs/companies" component={withTracker(Programs)}/>
            <Route exact path="/programs/government" component={withTracker(Govement)}/>
            <Route exact path="/publications-and-consulting/publications" component={withTracker(Publications)}/>
            <Route exact path="/garden" component={withTracker(Garden)}/>
            <Route exact path="/publications-and-consulting/consulting" component={withTracker(Consultation)}/>
            <Route exact path="/services" component={withTracker(Services)}/>
            <Redirect from="*" to="/"/>
          </Switch>
        </div>
    </Router>
  );
}

export default App;
