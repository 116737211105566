import React from 'react';

import axios from 'axios';

import {token, host} from '../../assets/options';

class MobileCategories extends React.Component {
    _isMounted = false;
    constructor(props){
        super(props);

        this.state = {
            companies: []
        }

        this.allow = true;
    }
    componentDidMount(){
        this._isMounted = true;

        function check(x) {
            if (x.matches && this.allow) {
                axios.post(host + '/api/programs/companies', {}, {headers: {'authorization': token}})
                .then(res => {
                    if (res.status === 200) {
                        if(this._isMounted)
                            this.setState({
                                companies: res.data.data
                            });

                        this.allow = false;

                        this.getData('', 'Բոլորը');
                    } else {
                    const error = new Error(res.data.error);
                    throw error;
                    }
                })
                .catch(err => {
                    console.error(err.response?err.response.data.error:err);
                }); 
            }
        }
          
        var media = window.matchMedia("(max-width: 1024px)")
        check.bind(this)(media);
        media.addListener(check.bind(this));
    }
    componentWillUnmount(){
        this._isMounted = false;
    }
    getData(id, name){
        this.props.this.getData(id, name);
    }
    onChange(el){
        this.getData(el.value, (el.value !== '')?el.value:'Բոլորը')
    }
    render(){
        return(
            <div className="MobileCategoriesContainer">
                <select name="cars" defaultValue="" onChange={(e)=>this.onChange(e.target)} className="selectpicker" id="cars">
                    <option value="">Բոլորը</option>
                    {this.state.companies.map(company=>{
                        return <option key={company._id} value={company._id}>{company.name}</option>
                    })}
                </select>
            </div>    
        );
    }
}

export default MobileCategories;