
import React from 'react';
import { Link } from 'react-router-dom';
import Header from '../../components/Header';
import Weather from '../../components/Weather';
import Footer from '../../components/Footer';
import Items from './Items';
import BigButtons from './BigButtons';
import MobileWeather from '../../components/MobileWeather';
import RoutesNav from '../../components/RoutesNav';

import axios from 'axios';

import { token, host } from '../../assets/options';

class Consultation extends React.Component {
    _isMounted = false;
    constructor(props) {
        super(props);
        this.state = {
            page: '',
            menu: true,
            buttons: true,
            ShowBurgerMenu: false,
            IsActiveButton: ' ',
            data: [],
            img: ''
        }
        // this.MouseOverHandler = this.MouseOverHandler.bind(this);
    }

    componentDidMount() {
        this._isMounted = true;

        this.getData();

        document.body.removeAttribute('id');
        window.scrollTo(0, 0);
        //header set color
        document.querySelector('.HrpActive').style.color = "#5BCA90";
        //
        const ActiveButton = document.querySelector('.bl_2');
        document.querySelector('.t2').style.color = 'white';
        ActiveButton.style.backgroundColor = '#30483C';
        ActiveButton.children[0].classList.add('IsWhiter2', 'IsActiveButton');
        // console.info('rendering');
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    async getData() {
        let data = (await axios.post(host + '/api/consulations/', { isPublication: false }, { headers: { 'authorization': token } })).data;
        if (this._isMounted)
            this.setState({
                data: data.data,
                img: data.main.img,
                isVideo: data.main.img.split('.')[data.main.img.split('.').length - 1] === 'mp4' ? true : false
            });
    }
    handleOver = (event, ButtonId) => {
        event.target.parentNode.style.backgroundColor = '#30483C';
        event.target.style.color = 'white';
        if (ButtonId === '1') {
            event.target.parentNode.children[0].classList.add('IsWhiter1');
        } else {
            event.target.parentNode.children[0].classList.add('IsWhiter2');
        }
        console.log('this is:', event.target);
    }
    handleOut = (event, ButtonId) => {
        event.target.parentNode.style.backgroundColor = '#ffff';
        event.target.style.color = '#30483C';
        if (ButtonId === '1') {
            event.target.parentNode.children[0].classList.remove('IsWhiter1');
        } else {
            event.target.parentNode.children[0].classList.remove('IsWhiter2');
        }
        console.log('this is:', event.target);
    }
    CloseBurgerMenuHandler = (event) => {
        this.setState({ ShowBurgerMenu: false });
        document.body.removeAttribute('id');
    }
    OpenBurgerMenuHandler = (event) => {
        this.setState({ ShowBurgerMenu: true });
        document.body.id = 'overBody';
    }
    render() {
        return (
            <div className="My-Container str">
                <div className="BurgerMenu" onClick={this.OpenBurgerMenuHandler}>
                    <div></div>
                    <div></div>
                    <div></div>
                </div>
                {
                    this.state.ShowBurgerMenu ? <RoutesNav CloseBurgerMenuHandler={this.CloseBurgerMenuHandler} class={'Routing_nav posfixer MobileFixers'} /> : null
                }
                <Link to="/" className="My_Logo"></Link>
                <Header />
                <div className="TitleBlock" style={{ backgroundImage: `url(${this.state.img !== '' && !this.state.isVideo ? `${host}/public/uploads/${this.state.img}` : ''})` }}>
                    <div className="mytitle_2">
                        <div className="mytxt">
                            <p>Գլխավոր</p>
                        </div>
                    </div>
                    <div className="ctext">
                        <h1>Հրապարակումներ և խորհրդատվություն</h1>
                    </div>

                    {this.state.isVideo ? <video className="bgVideo" ref={el => this.video = el} style={{ width: '100%' }} playsInline autoPlay loop muted>
                        <source src={`${host}/public/uploads/${this.state.img}`} type="video/mp4" />
                    </video> : null}
                </div>
                {/*  */}
                <BigButtons handleOut={this.handleOut} handleOver={this.handleOver} />
                <Items data={this.state.data} />
                <Weather />
                <MobileWeather />
                <Footer />
            </div>);
    }
}

export default Consultation;