import React from 'react';

import { host } from '../../assets/options';

const Item = props => {
    var item = props.data;

    return (
        <div className="flex-item">
            <a href={item.url} className="consulting__atag" target="_blank" rel="noopener noreferrer">
                <div className="Shadow"></div>
                    <div className="mycontext consulting__mycontext">
                    <p className='consulting__title'>{item.name}</p>
                </div>
                
                <div className="img_container" style={{backgroundImage: `url(${item.img !== ''?`${host}/public/uploads/${item.img}`:''})`}}></div>
            </a>
        </div>
    );
}

class Items extends React.Component {
    constructor(props) {
        super(props);

        this.state={
            items: [],
            showAll: false
        }
    }

    componentWillReceiveProps (nextProps) {
        this.setState({items: nextProps.data})
    }
    
    loadItems () {
        var items = [];

        let mql = window.matchMedia('(max-width: 1024px)').matches;

        var length = (!this.state.showAll && mql && this.state.items.length > 3)?3:this.state.items.length;
        
        for (var i = 0; i < length; i++) {
            items.push(<Item key={i} data={this.state.items[i]} />);
        } 

        if (!this.state.showAll && mql && this.state.items.length > 3) items.push(<button className="seeMoreBtn" onClick={()=>{this.setState({showAll: true})}}>Տեսնել բոլորը</button>);

        return items;
    }

    render () {
        return (
            <React.Fragment>
                <div className="MyBodyContainer">            
                    <div className="flex-container">
                        {this.loadItems()}
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default Items;