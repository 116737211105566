import React from 'react';

import { months } from '../assets/options';

import axios from 'axios';

import { token, host, weatherStates } from '../assets/options';

// import Slider from "react-slick";

import RBCarousel from "react-bootstrap-carousel";
// import { Row, Col, Button, ButtonGroup } from "./bootstrap-component.jsx";
// import "react-bootstrap-carousel/dist/react-bootstrap-carousel.css";

class Weather extends React.Component {
    _isMounted = false;

    constructor(props) {
        super(props);

        this.state = {
            data: {
                yerevan: {},
                kotayk: {},
                ararat: {},
                armavir: {},
                gegharkunik: {},
                aragatsotn: {}
            },

            ad: {},

            slider_time: 0
        }
    }
    
    componentDidMount () {
        this._isMounted = true;

        axios.post(host + '/api/weather/', {}, {headers: {'authorization': token}})
        .then(res => {
            if (res.status === 200) {
                if(this._isMounted)
                    this.setState({
                        data: res.data.data
                    });
            } else {
              const error = new Error(res.data.error);
              throw error;
            }
        })
        .catch(err => console.error(err.response?err.response.data.error:err)); 

        axios.post(host + '/api/ads/bySize/360x200', {}, {headers: {'authorization': token}})
        .then(res => {
            if (res.status === 200) {
                if (this._isMounted) {
                    this.setState({
                        ad: res.data.data,
                        slider_time: res.data.time
                    });
                }
            } else {
              const error = new Error(res.data.error);
              throw error;
            }
        })
        .catch(err => console.error(err.response ? err.response.data.error : err)); 
    }
    
    componentWillUnmount () {
        this._isMounted = false;
    }

    render () {
        const ClimatBlockTop = () => {
            var today = new Date();

            return (
                <div className="ClimatBlock">
                    <div className="ClimatLocation">
                        <p>Այժմ, {today.getDate()} {months[today.getMonth()]}</p>
                    </div>
            
                    <div className="ctdis">
                        <p>Կոտայք</p>
                    </div>
                    <div className="ctdis">
                        <p>Արարատ</p>
                    </div>
                    <div className="ctdis">
                        <p>Արմավիր</p> 
                    </div>
                    <div className="ctdis">
                        <p>Գեղարքունիք</p> 
                    </div>
                    <div className="ctdis">
                        <p>Արագածոտն</p>
                    </div>
                </div>
            );
        }
        
        const MiddleClimatBlock3 = () => {
            return (
                <div className="MidlleClimatContextBlock">
                <div className="ContainerMiddle cntend">
                    <p>Խոնավություն` <span className="pl">{this.state.data.yerevan.humidity}%</span></p>
                    <p>Քամու արագություն՝ <span className="pl">{this.state.data.yerevan.wind} կմ/ժ</span></p>
                </div>
        
                <div className="ctdis eder">
                    <p>{this.state.data.kotayk.state?weatherStates[this.state.data.kotayk.state.toLowerCase()]:null}</p>
                    {/* <img alt="err" src={require('../views/images/Icons/Weather/175855-weather-icon-compilation/png/foggy-3.png')}></img>   */}
                </div>
                
                <div className="ctdis eder">
                <p>{this.state.data.ararat.state?weatherStates[this.state.data.ararat.state.toLowerCase()]:null}</p>
                    {/* <img alt="err" src={require('../views/images/Icons/Weather/175855-weather-icon-compilation/png/foggy-3.png')}></img>   */}
                </div>
                
                <div className="ctdis eder">
                <p>{this.state.data.armavir.state?weatherStates[this.state.data.armavir.state.toLowerCase()]:null}</p>
                    {/* <img alt="err" src={require('../views/images/Icons/Weather/175855-weather-icon-compilation/png/foggy-3.png')}></img> */}
                </div>
                
                <div className="ctdis eder">
                <p>{this.state.data.gegharkunik.state?weatherStates[this.state.data.gegharkunik.state.toLowerCase()]:null}</p>
                    {/* <img alt="err" src={require('../views/images/Icons/Weather/175855-weather-icon-compilation/png/foggy-3.png')}></img> */}
                </div>
                
                <div className="ctdis eder">
                   <p>{this.state.data.aragatsotn.state?weatherStates[this.state.data.aragatsotn.state.toLowerCase()]:null}</p>
                    {/* <img alt="err" src={require('../views/images/Icons/Weather/175855-weather-icon-compilation/png/foggy-3.png')}></img>  */}
                </div>
        
             </div> 
            );
        }
        
        const MiddleClimatBlock = () => {
            return (
                <div className="MidlleClimatContextBlock">
                <div className="ContainerMiddle">
                    <p>Երեւան</p>
                    <p>{this.state.data.yerevan.state?weatherStates[this.state.data.yerevan.state.toLowerCase()]:null}</p>
                </div>
                
                <div className="ctdis eder">
                    {this.state.data.kotayk.state?<img alt="err" src={`${host}/public/img/weatherIcons/${this.state.data.kotayk.state.toLowerCase()}.png`}></img>:null}  
                </div>
                
                <div className="ctdis eder">
                    {this.state.data.ararat.state?<img alt="err" src={`${host}/public/img/weatherIcons/${this.state.data.ararat.state.toLowerCase()}.png`}></img>:null}
                </div>
                
                <div className="ctdis eder">
                    {this.state.data.armavir.state?<img alt="err" src={`${host}/public/img/weatherIcons/${this.state.data.armavir.state.toLowerCase()}.png`}></img>:null}
                </div>
                <div className="ctdis eder">
                    {this.state.data.gegharkunik.state?<img alt="err" src={`${host}/public/img/weatherIcons/${this.state.data.gegharkunik.state.toLowerCase()}.png`}></img>:null} 
                </div>
        
                <div className="ctdis eder">
                    {this.state.data.aragatsotn.state?<img alt="err" src={`${host}/public/img/weatherIcons/${this.state.data.aragatsotn.state.toLowerCase()}.png`}></img>:null}  
                </div>
             </div> 
            );
        }

        const MiddleClimatBlock2 = () => {
            return(
                <div className="MidlleClimatContextBlock">
                <div className="ContainerMiddle">
                    <p className="sombl">{this.state.data.yerevan.temperature>0?'+':''}{this.state.data.yerevan.temperature}</p>
                    {this.state.data.yerevan.state?<img alt="err" className="ClimatImg" src={`${host}/public/img/weatherIcons/${this.state.data.yerevan.state.toLowerCase()}.png`}></img>:null}
                    {/* <p>15:30, ուրբաթ</p> */}
                </div>
        
                <div className="ctdis eder mieder">
                    <p>{this.state.data.kotayk.temperature>0?'+':''}{this.state.data.kotayk.temperature}</p>
                </div>
                
                <div className="ctdis eder mieder">
                    <p>{this.state.data.ararat.temperature>0?'+':''}{this.state.data.ararat.temperature}</p>
                </div>
                
                <div className="ctdis eder mieder">
                    <p>{this.state.data.armavir.temperature>0?'+':''}{this.state.data.armavir.temperature}</p>
                </div>
                
                <div className="ctdis eder mieder">
                    <p>{this.state.data.gegharkunik.temperature>0?'+':''}{this.state.data.gegharkunik.temperature}</p>
                </div>
                
                <div className="ctdis eder  mieder">
                    <p>{this.state.data.aragatsotn.temperature>0?'+':''}{this.state.data.aragatsotn.temperature}</p>
                </div>
        
             </div> 
            );
        }

        return (
            <div className="ClimateContainer">
            <div className="climat_title">
                Եղանակը մարզերում
            </div>
            <div className="Climat_Body">
                <div className="climat_context_container">
                    <ClimatBlockTop/> 

                    <MiddleClimatBlock/>

                    <div className="BottomClimatContextBlock">
                        <MiddleClimatBlock2/>
                        <MiddleClimatBlock3/> 
                    </div>
                </div>
                
                {this.state.ad.length ? <RBCarousel
                    autoplay={true}
                    pauseOnVisibility={true}
                    slideshowSpeed={this.state.slider_time * 1000}
                    version={4}
                    indicators={false}
                >
                    {this.state.ad.map((item, key) => {
                        return (
                            <a href={item.url} key={key} target="_blank" rel="noopener noreferrer">
                                <div className="climat_Image_Container" 
                                style={{backgroundImage: `url(${`${host}/public/uploads/${item.img}`})`}}></div> 
                            </a>
                        )
                    })}
                </RBCarousel> : null}
            </div>
        </div>
    );
    }
}

export default Weather