import React from 'react';
// import LeftMenu from './LeftMenu';
import { Link } from 'react-router-dom';

// const UrlPath = window.location.pathname;

import {host} from '../../assets/options';

const Item = props =>{
    var item = props.data;
        return (
            <React.Fragment>
                <div className="progItem karpos">
                <div className="ProgNameContainer">
                <div className="TitleInfo prognameTtitle">
                            <a href={item.url} target="_blank" rel="noopener noreferrer" className="titl titlprog">
                            {item.name}
                            </a>
                        </div>
                </div>
                    <div className="regcont">
                    <div className="ImageBlockContainer" style={{backgroundImage: `url(${item.img !== ''?`${host}/public/uploads/${item.img}`:''})`}}></div>
                    <div className="MyInfoContainer">
                        <div className="TitleInfo compImgTitle">
                            <a href={item.url} target="_blank" rel="noopener noreferrer" className="titl">
                            {item.name}
                            </a>
                        </div>
                        <div className="InfoTextContainer prgInfo">
                            <p className="mytext_1 fxt" dangerouslySetInnerHTML={{__html: item.text}}></p>
                        </div>
                        <div className="MyButtonShow">
                            <a href={item.url} target="_blank" rel="noopener noreferrer" className="showLink">իմանալ ավելին <div className="swimg"></div></a>
                        </div>        
                    </div>
                    </div>
                </div>
            </React.Fragment>
        ); 
}

class Items extends React.Component{
    constructor(props){
        super(props);
        
        this.state = {
            showAll: false,
            items: []
        }
    }
    componentWillReceiveProps(nextProps){
        this.setState({items: nextProps.data})
    }
    loadItems(){
        var items = [];

        let mql = window.matchMedia('(max-width: 1024px)').matches;

        var length = (!this.state.showAll && mql && this.state.items.length > 2)?2:this.state.items.length;
        
        for(var i = 0; i < length; i++){
            items.push(<Item key={i} data={this.state.items[i]} />);
        } 

        if(!this.state.showAll && mql && this.state.items.length > 2) items.push(<button className="seeMoreBtn" onClick={()=>{this.setState({showAll: true})}}>Տեսնել բոլորը</button>);

        return items;
    }
    render(){
        return(
            <React.Fragment>
                <div className="MyBodyContainer InMenu">  
                    {/* <LeftMenu />  */}

                    <div className="ResultsContainer">
                        {/* <p className="categoryTitle">այգու հիմնում</p> */}
                        {/* <p className="SeachResults progr mprogm">ծրագրի անվանումը</p> */}
                    </div> 

                    <div className="flex-container flexfixer aygflex progFlex progFlex2">
                    {this.loadItems()}
                    </div>
                </div>
            </React.Fragment>
        );
    }
}
export default Items;