import React from 'react';
import RoutesNav from './RoutesNav';
import Logos from './Logos';

import axios from 'axios';

import { token, host } from '../assets/options';

class Footer extends  React.Component {
    constructor() {
        super();

        this.state = {
            yerevan: {},
            kotayk: {},
            ararat: {},
            armavir: {},
            gegharkunik: {},
            aragatsotn: {},
            email: ""
        }
    }
    
    componentDidMount () {
        axios.post(host + '/api/home/footer/', {}, {headers: {'authorization': token}})
        .then(res => {
            if (res.status === 200) {
                this.setState({email: res.data.email})
            } else {
              const error = new Error(res.data.error);
              throw error;
            }
        })
        .catch(err => console.error(err.response ? err.response.data.error : err)); 
    }

    render () {
        return (
            <>
                <Logos />

                <div className="My-Footer fposfix">
                    <div className="Footer_Container">
                        <div className="Footer_Logo"></div>
                        <div className="footer_texts_block">
                            <div className="Footer_adress">{this.state.email.split("|")[2]}</div>
                            <div className="Footer_Contacts">{this.state.email.split("|")[1]}</div>
                            <div className="Footer_Contacts">{this.state.email.split("|")[0]}</div>
                        </div>
                    </div>
                    
                    <div className="Mini_f">
                        <span>© {new Date().getFullYear()} AYGI</span> <span>Made by <a href="https://weflex.am/" rel="noopener noreferrer" target="_blank">WeFlex</a></span>
                    </div>
                    
                    <RoutesNav class={'Routing_nav posfixer '}/>
                </div>
            </>
        );
    }
}

export default Footer;