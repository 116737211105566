import React from 'react';
import LeftMenu from './LeftMenu';
import Profile from './Profile';
import { host } from '../../assets/options';

const Item = props => {
    var item = props.data;
        return (
            <div className="flexItemMenu aygitem" onClick={()=>props.OpenProfileHandler(item._id)}>
                <div className="Shadow aygishadow noner"></div>
                <div className="mycontext aygi-context">
                    {/* <p> Այգու կառավարում</p> */}
                    {/* <button className="center_btn tsn agnb"><i className="fa fa-play fa-2x"/></button> */}
                </div>
                <div className="DisCriptionBlock">
                    <p className="dp cdp">{item.name} <i className="fas fa-angle-right"></i></p>
                    <p className="dp_2 cdp_2">
                        {/* Փորձ՝ {item.experience_num} տ. */}
                    </p>
                </div>
                <div className="img_container aygitemImg" style={{backgroundImage: `url(${item.img !== ''?`${host}/public/uploads/${item.img}`:''})`}}></div>
            </div>
        ); 
}

// MyBodyContainer InMenu
// flex-container 
// {props.menu === true ? <LeftMenu/> :null}

class Items extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            showProfile:false,
            profileData: {},
            items: [],
            showAll: false,
            categoryName: ''
        }

        this.OpenProfileHandler = this.OpenProfileHandler.bind(this);
    }
    
    componentWillReceiveProps (nextProps) {
        this.setState({
            items: nextProps.data,
            categoryName: nextProps.category
        });
    }
    
    OpenProfileHandler (id) {
        this.setState({showProfile:true, profileData: id});
        document.body.id = 'overBody';
    }
    
    CloseProfileHandler (event) {
        this.setState({showProfile:false});
        document.body.removeAttribute('id');
        event.target.style.display = 'none';
    }
    
    loadItems () {
        var items = [];

        let mql = window.matchMedia('(max-width: 1024px)').matches;

        var length = (!this.state.showAll && mql && this.state.items.length > 3)?3:this.state.items.length;
        
        for (var i = 0; i < length; i++) {
            items.push(<Item key={i} data={this.state.items[i]}  OpenProfileHandler={this.OpenProfileHandler}/>);
        } 

        if(!this.state.showAll && mql && this.state.items.length > 3) items.push(<button className="seeMoreBtn" onClick={()=>{this.setState({showAll: true})}}>Տեսնել բոլորը</button>);

        return items;
    }

    render () {
        return (
            <React.Fragment>
                <div className="MyBodyContainer InMenu">  
                    {
                        this.state.showProfile ? <Profile data={this.state.profileData} CloseProfileHandler={this.CloseProfileHandler.bind(this)}/>:null
                    }
                    <LeftMenu this={this.props.this} /> 

                    <div className="ResultsContainer">
                        <p className="categoryTitle fixc">{this.state.categoryName}</p>
                        <p className="SeachResults myres">գտնվել է {this.state.items.length} մասնագետ</p>
                    </div> 

                    <div className="flex-container flexfixer aygflex">
                    
                    {this.loadItems()}
                    </div>
                </div>
            </React.Fragment>
        );
    }
}
export default Items;