import React from 'react';
import { Link } from 'react-router-dom';

// const UrlPath = window.location.pathname;

const BigButtons = (props) => {
        return(
            <div className="SeterContainer">
            <div className="SeterBlock">
            
                <div  className="buter bl_1">
                {/* <div className="ClickerIs"></div> */}
                <span className="iconer ike"></span>
                    {/* <span className="t1" href="/">Հրապարակումներ</span> */}
                    <Link to="/publications-and-consulting/publications" className="t1">Հրապարակումներ</Link>
                </div>

                <div  className="buter bl_2">
                    <span className="iconer ike b2"></span>
                    <Link className="t2" to="/publications-and-consulting/consulting">խորհրդատվություն</Link>
                </div>
            </div>
        </div>
        )
}

export default BigButtons;