import React from 'react';
import LeftMenu from './LeftMenu';
import { Link } from 'react-router-dom';

import { host } from '../../assets/options';

// const UrlPath = window.location.pathname;

const Item = props => {
    var item = props.data;
        return (
            <div className="progItem">
                <div className="ImageBlockContainer" style={{backgroundImage: `url(${item.img !== ''?`${host}/public/uploads/${item.img}`:''})`}}></div>
                <div className="MyInfoContainer">
                    <div className="TitleInfo">
                        {`${item.name}` /* | ${item.category}*/}
                    </div>
                    <div className="InfoTextContainer">
                        <p className="mytext_1" dangerouslySetInnerHTML={{__html: item.text}}></p>
                    </div>
                    <div className="MyButtonShow">
                        {item.custom_urls[0] ? 
                        <a href={item.custom_urls[0].url} target="_blank" rel="noopener noreferrer" className="customLink">{item.custom_urls[0].text}</a>:null}
                        {item.custom_urls[1] ? <a href={item.custom_urls[1].url} target="_blank" rel="noopener noreferrer" className="customLink">{item.custom_urls[1].text}</a>:null}
                    </div>        
                </div>
            </div>
        );  
}

// MyBodyContainer InMenu
// flex-container 
// {props.menu === true ? <LeftMenu/> :null}

class Items extends React.Component {
    constructor(props) {
        super(props);
        
        this.state = {
            showAll: false,
            items: []
        }
    }
    
    componentWillReceiveProps(nextProps) {
        this.setState({
            items: nextProps.data
        });
    }

    loadItems () {
        var items = [];

        let mql = window.matchMedia('(max-width: 1024px)').matches;

        var length = (!this.state.showAll && mql && this.state.items.length > 2)?2:this.state.items.length;
        
        for(var i = 0; i < length; i++){
            items.push(<Item key={i} data={this.state.items[i]} />);
        } 

        if(!this.state.showAll && mql && this.state.items.length > 2) items.push(<button key="aaa" className="seeMoreBtn" onClick={()=>{this.setState({showAll: true})}}>Տեսնել բոլորը</button>);

        return items;
    }
    render(){
        return(
            <React.Fragment>
                <div className="MyBodyContainer InMenu">  
                    <LeftMenu this={this.props.this}/> 

                    <div className="ResultsContainer">
                        {/* <p className="categoryTitle">այգու հիմնում</p> */}
                        <p className="SeachResults myprog">գտնվել է {this.state.items.length} ընթացիկ ծրագիր</p>
                    </div> 

                    <div className="flex-container flexfixer aygflex">
                        {this.loadItems()}
                    </div>
                </div>
            </React.Fragment>
        );
    }
}
export default Items;