import React from 'react';

class Video extends React.Component {
    constructor(props){
        super(props);

        this.state = {
            videoLink: ''
        }
    }
    componentDidMount(){
        this.setState({
            videoLink: this.props.videoLink
        })
    }
    componentWillReceiveProps(nextProps){
        this.setState({
            videoLink: nextProps.videoLink
        })
    }
    render(){
        return(
            <div className="ProfileContainer" onClick={this.props.CloseVideoHandler}>
                <span  className="close" id="VideoCloser" onClick={this.props.CloseVideoHandler}></span>
                <div className="ProfileBlock videoPlayer videoPlayerMobile">
                <iframe title="player" allowFullScreen src={this.state.videoLink}>
                </iframe>
                </div>
            </div>
        );
    }
}

export default Video;