import React from 'react';
import { Link } from "react-router-dom";

import { withRouter } from "react-router";

const RoutesNav = props => {
    return (
        <div className={props.class}>
               <span className="close MenuCloser" onClick={props.CloseBurgerMenuHandler}></span>
                    <div className="Routes_Container">
                        <div className="Route_Link_Block bc1">
                            <Link 
                            className={props.location.pathname === "/publications-and-consulting/publications" ? "center__text" : ""}
                            onClick={props.CloseBurgerMenuHandler} 
                            to={props.location.pathname === "/publications-and-consulting/publications" ? "/garden" : "/publications-and-consulting/publications"}>
                                {props.location.pathname === "/publications-and-consulting/publications" ? "Տեսանյութեր" : "Հրապարակումներ և խորհրդատվություն"}
                            </Link>
                        </div>
                        <div className="Route_Link_Block bc2">
                            <Link 
                            onClick={props.CloseBurgerMenuHandler} 
                            to={
                                props.location.pathname === "/" || 
                                props.location.pathname === "/garden" ||
                                props.location.pathname === "/publications-and-consulting/publications" ||
                                props.location.pathname === "/programs/companies" ||
                                props.location.pathname === "/services"
                                ? "/trainings" : "/garden"
                            }
                            >

                                {
                                    props.location.pathname === "/" || 
                                    props.location.pathname === "/garden" ||
                                    props.location.pathname === "/publications-and-consulting/publications" ||
                                    props.location.pathname === "/programs/companies" ||
                                    props.location.pathname === "/services"
                                    ? "Վերապատրաստումներ" : "Տեսանյութեր"
                                }
                            </Link>
                        </div>
                        <div className="Route_Link_Block bc3">
                            <Link 
                                onClick={props.CloseBurgerMenuHandler} 
                                to={props.location.pathname === "/programs/companies" ? "/garden" : "/programs/companies"}
                            >
                                {props.location.pathname === "/programs/companies" ? "Տեսանյութեր" : "Ծրագրեր"}
                            </Link>
                        </div>
                        <div className="Route_Link_Block bc4">
                            <Link
                                onClick={props.CloseBurgerMenuHandler}
                                to={props.location.pathname === "/services" ? "/garden" : "/services"}
                            >
                                {props.location.pathname === "/services" ? "Տեսանյութեր" : "Ծառայություններ"}
                            </Link>
                        </div>
                    </div>
                </div>    
    );
}

export default withRouter(RoutesNav);