import React from 'react';

import axios from 'axios';

import {token, host} from '../../assets/options';


class LeftMenu extends React.Component{
    _isMounted = false;
    constructor(props){
        super(props);

        this.state = {
            companies: []
        }

        this.allow = true;
    }
    componentDidMount(){
        this._isMounted = true;

        function check(x) {
            if (!x.matches && this.allow) {
                axios.post(host + '/api/trainings/companies', {}, {headers: {'authorization': token}})
                .then(res => {
                    if (res.status === 200) {
                        if(this._isMounted)
                            this.setState({
                                companies: res.data.data
                            });

                        this.allow = false;

                        this.getData('', 'Բոլորը');
                    } else {
                      const error = new Error(res.data.error);
                      throw error;
                    }
                  })
                .catch(err => {
                    console.error(err.response?err.response.data.error:err);
                }); 
            }
        }
          
        var media = window.matchMedia("(max-width: 1024px)")
        check.bind(this)(media);
        media.addListener(check.bind(this));        
    }
    componentWillUnmount(){
        this._isMounted = false;
    }
    getData(id, name){
        this.props.this.getData(id, name);
    }
    render(){
        return(
            <div className="LeftMenuContainer">
                <p>Կազմակերպության անվանումը</p>
                <ul>
                    <li>
                        <span onClick={()=>this.getData('', 'Բոլորը')}>Բոլորը</span>
                    </li>
                    {this.state.companies.map((company, i)=>{
                        return <li key={i}>
                            <span onClick={()=>this.getData(company._id, company.name)}>{company.name}</span>
                        </li>
                    })}
                </ul>
            </div>
        );
    }        
}

export default LeftMenu;