import React from 'react';
import LeftMenu from './LeftMenu';
import Video from './Video';
// import { Link } from 'react-router-dom';

// const UrlPath = window.location.pathname;

import {host, months} from '../../assets/options';

const Item = props => {
    var item = props.data;

    item.date = new Date(item.date);
        return (
            <div className="flexItemMenu aygitem" onClick={()=>props.OpenVideoHandler(item.video)}>
                <div className="Shadow aygishadow"></div>
                <div className="mycontext aygi-context">
                    {/* <p> Այգու կառավարում</p> */}
                    <button className="center_btn tsn agnb"><i className="fa fa-play fa-2x"/></button>
                </div>
                <div className="DisCriptionBlock">
                    <p className="dp" id="keker">{item.name}</p>
                    {/* <p className="dp_2">
                        {item.date.getMonth() < 10 ? "0"+item.date.getMonth() : item.date.getMonth()} / {item.date.getFullYear().toString().slice(-2)}
                    </p> */}
                </div>
                <div className="img_container aygitemImg" style={{backgroundImage: `url(${item.img !== ''?`${host}/public/uploads/${item.img}`:''})`}}>
                </div>
            </div>
        ); 
}

// MyBodyContainer InMenu
// flex-container 
// {props.menu === true ? <LeftMenu/> :null}

class Items extends React.Component{
        constructor(props) {
            super(props);

            this.state = {
                showVideo: false,
                items: [],
                showAll: false,
                videoLink: '',
                categoryName: '',
            }
        }

        componentDidMount () {
            this.setState({
                items: this.props.data
            });
        }
        
        componentWillReceiveProps (nextProps) {
            this.setState({
                items: nextProps.data,
                categoryName: nextProps.category
            });
        }
        
        OpenVideoHandler (videoLink) {
            this.setState({showVideo: true, videoLink: videoLink});
            document.body.id = 'overBody';
        }

        CloseVideoHandler (event) {
            this.setState({showVideo:false});
            document.body.removeAttribute('id');
            event.target.style.display = 'none';
        }

        loadItems () {
            var items = [];
    
            let mql = window.matchMedia('(max-width: 1024px)').matches;
    
            var length = (!this.state.showAll && mql && this.state.items.length > 3) ? 3 : this.state.items.length;
            
            for (var i = 0; i < length; i++) {
                items.push(<Item key={i} data={this.state.items[i]} OpenVideoHandler={this.OpenVideoHandler.bind(this)}/>);
            } 
    
            if (!this.state.showAll && mql && this.state.items.length > 3) items.push(<button className="seeMoreBtn" onClick={()=>{this.setState({showAll: true})}}>Տեսնել բոլորը</button>);
    
            return items;
        }

        render () {
            return (
                <React.Fragment>
                    <div className="MyBodyContainer InMenu">
                        {
                            this.state.showVideo ? <Video videoLink={this.state.videoLink} CloseVideoHandler={this.CloseVideoHandler.bind(this)}/>:null
                        }

                        <LeftMenu this={this.props.this}/> 

                        <div className="ResultsContainer">
                            <p className="categoryTitle">{this.state.categoryName}</p>
                            <p className="SeachResults">գտնվել է {this.state.items.length} տեսանյութ</p>
                        </div> 

                        <div className="flex-container flexfixer aygflex">
                            {this.loadItems()}
                        </div>
                    </div>
                </React.Fragment>
            );
        }
}
export default Items;